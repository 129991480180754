import React from 'react';
import './App.css';
import {v4 as uuidv4} from 'uuid'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { ocean } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import SyntaxHighlighter from 'react-syntax-highlighter'

type Props = {}

type LogEntry = {
  id: String,
  body: String,
}

type State = {
  url: string;
  payload: string;
  endpointId: string;
  log: Array<LogEntry>;
  offset: number,
}

class App extends React.Component<Props, State> {
  state: State = {
    url: '',
    payload: '',
    endpointId: '',
    log: [],
    offset: 0,
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div>
            <h1>req*log</h1>
            <h3>Create an event stream with a click.<br/>Publish and consume events with plain HTTP.</h3>
          </div>
        </header>
        <div className='main'>
          <div className={`input-area visible ${this.state.url && 'hhidden'}`}>
            <div>
              <button id='send' onClick={this.generateLog}>Create a Log</button>
            </div>
          </div>
            <div className='interactive'>
              <div className='guide'>
                <div>
                  {this.renderPublishGuide()}
                </div>
                <div>
                  {this.renderConsumeGuide()}
                </div>
              </div>
              {this.renderLog()}
            </div>
         </div>
      </div>
    );
  }

  renderPublishGuide = () => {
    const url = this.state.endpointId ?
      process.env.REACT_APP_SERVER_URL + "/log/" + this.state.endpointId + "/publish" :
      process.env.REACT_APP_SERVER_URL + "/log/<log id>/publish"
    const exampleCode = `fetch('${url}', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({'id': 42, 'body': 'Hello World'})
})`
    return (
      <>
        <b>Publishing an event</b>
        <SyntaxHighlighter language="javascript" style={atomOneDark}>
          {exampleCode}
        </SyntaxHighlighter>
      </>
    )
  }

  renderConsumeGuide = () => {
    const url = this.state.endpointId ?
      process.env.REACT_APP_SERVER_URL + "/log/" + this.state.endpointId + "/consume" :
      process.env.REACT_APP_SERVER_URL + "/log/<log id>/consume"
    const exampleCode =
`const evtSource = new EventSource(
  '${url}'
)
evtSource.onmessage = (event) => {
  process(event)
}`

    return (
      <>
        <b>Consuming events with SSE</b>
        <SyntaxHighlighter language="javascript" style={atomOneDark}>
          {exampleCode}
        </SyntaxHighlighter>
      </>
    )
  }

  renderLog = () => {
    const log = this.state.log
    return (
        <div className='log'>
          <h3>Your event log:</h3>
          {!this.state.url &&
            <p className='example'>
              Create a log to send requests to.
            </p>
          }
          {this.state.url &&
            <>
              <p>Your log is at <span className='url'>{this.state.endpointId}</span></p>
              <p className='example'>
                <button id='test-publish' onClick={this.sendTest}>Publish a test event</button>
              </p>
            </>
            }
          {log.map ((entry, i) => (
            <div className='log-entry' key={i}>
              <p><b>ID</b>: {entry.id}</p>
              <p><b>Body</b>: {entry.body}</p>
            </div>
          ))}
        </div>
    );
  }

  generateLog = () => {
    const url = process.env.REACT_APP_SERVER_URL + "/log"
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'text/plain',
        'Accept': 'text/plain'
      }
    })
    .then(this.handleServerResponse)
    .then(this.fetchLog)
  }

  sendTest = () => {
    this.publishMsg(`This is message #${this.state.log.length}`)
  }

  handleServerResponse = (resp: any) => {
    return resp.text().then((text: string) => {
      const pathnameParts = new URL(text).pathname.split("/")
      const endpointId = pathnameParts[pathnameParts.length - 1]
      console.log(this.setState)
      this.setState({
        url: text,
        endpointId: endpointId
      })
    });
  }

  publishMsg = (body: String) => {
    const url = process.env.REACT_APP_SERVER_URL + "/log/" + this.state.endpointId + "/publish"
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({'id': uuidv4(), 'body': body})
    })
  }

  fetchLog = () => {
    const url = process.env.REACT_APP_SERVER_URL + "/log/" + this.state.endpointId + "/consume/webdemo"
    const evtSource = new EventSource(url)
    evtSource.onopen = function(e) {
      console.log(evtSource)
    }
    evtSource.onerror = function(err) {
      console.log(err)
    }
    evtSource.onmessage = (e) => {
      const data = JSON.parse(e.data)
      this.setState({
        log: [...this.state.log, data],
        offset: this.state.offset + 1,
      })
    }
//     fetch(url, {
//       method: 'GET',
//       redirect: 'follow',
//       mode: 'cors',
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     }).then(async (resp: any) => {
//       const json = await resp.json()
//       this.setState({
//         log: json
//       })
//     });
  }
}

export default App;
